import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "/",
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/main/index.vue')
    // component: () => import('@/views/main/index1.vue')
  },
  {
    path: '/home1',
    name: 'Home1',
    // component: () => import('@/views/main/index1.vue')
    component: () => import('@/views/main/index1.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/index.vue'),
    meta: {
      title: '登录页'
    }
  },
  {
    path: '/relation',
    name: 'Relation',
    component: () => import('@/views/relation/index.vue'),
    meta: {
      title: '联系我们'
    }
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
